import axios from 'axios';

export default class MedicineLabGroups {
  schedules() {
    return axios.get('/api/medicine-lab-groups')
  }

  chooseSchedule(scheduleId) {
    return axios.patch(`/api/medicine-lab-groups/${scheduleId}`)
  }

  deleteSchedule(scheduleId) {
    return axios.delete(`/api/medicine-lab-groups/${scheduleId}`)
  }
}
