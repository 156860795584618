<template>
      <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>

                <div class="row">
                  <div class="col-12 mb-5">
                    <div class="card-primary h-100">
                      <div class="card-body d-flex align-items-center text-left">
                        <p v-html="$t('medicine-lab-groups.instruction')"></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mb-5 schedule">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>{{ $t('medicine-lab-groups.group') }}</th>
                        <th>{{ $t('medicine-lab-groups.mentor') }}</th>
                        <th>{{ $t('medicine-lab-groups.day') }}</th>
                        <th>{{ $t('medicine-lab-groups.time') }}</th>
                        <th>&nbsp;</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr @click="handleChoose(schedule, $event)"
                          :class="['cursor-pointer', { 'has-chosen': schedule.has_chosen }, { 'invalid': schedule.students_count > 4 } ]"
                          v-for="schedule of schedules">
                        <td :data-label="$t('medicine-lab-groups.group')">
                          {{ helpers.getLocalizedField(schedule.group, 'name') }}
                        </td>
                        <td :data-label="$t('medicine-lab-groups.mentor')">
                          {{ helpers.getLocalizedField(schedule.mentor, 'fname') }}
                          {{ helpers.getLocalizedField(schedule.mentor, 'lname') }}
                        </td>
                        <td :data-label="$t('medicine-lab-groups.day')">
                          {{ helpers.getLocalizedField(schedule.day, 'day') }}
                        </td>
                        <td :data-label="$t('medicine-lab-groups.time')">
                          {{ schedule.start }} - {{ schedule.end }}
                        </td>
                        <td>
                          <span @click="handleDelete(schedule.id)" v-if="schedule.has_chosen">
                            <img
                               src="/static/images/icons/registrations/close.png" />
                              {{ $t('medicine-lab-groups.delete-schedule') }}
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import MedicineLabGroups from '../api/medicineLabGroups';

const resource = new MedicineLabGroups()

export default {
    name:'medicine-lab-groups',
    components: { sidebar, appHeader, pageHeader },
    computed: {
        pageData() {
        return {
            title: this.$t('medicine-lab-groups.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('medicine-lab-groups.title'),
            link: '/medicine-lab-groups',
            isActive: true,
            }],
        };
        },
    },

  data() {
    return {
      schedules: []
    }
  },

  mounted() {
     this.loadSchedules()
  },

  methods: {
    loadSchedules() {
      resource.schedules()
      .then(resp => {
        this.schedules = resp.data.data
      })
    },

    handleChoose(schedule, $event) {
      if ($event.target.tagName === 'SPAN' || $event.target.tagName === 'IMG') {
        this.handleDelete(schedule.id)
        return
      }

      if (schedule.students_count > 4) return

      resource.chooseSchedule(schedule.id)
      .then(() => {
        this.helpers.notifySuccessMessage(this.$t('medicine-lab-groups.successfully-chosen'))
        this.loadSchedules()
      })
      .catch(err => {
        this.helpers.notifyErrorMessage(err)
      })
    },

    handleDelete(scheduleId) {
      resource.deleteSchedule(scheduleId)
      .then(() => {
        this.helpers.notifySuccessMessage(this.$t('medicine-lab-groups.successfully-deleted'))
        this.loadSchedules()
      })
      .catch(err => {
        this.helpers.notifyErrorMessage(err)
      })
    }
  }
}
</script>
<style scoped>
  .invalid td {
    background-color: #ffd178 !important;
    color: #000 !important;
  }
  .has-chosen td {
    background-color: #557C84 !important;
    color: #fff !important;
  }
</style>
